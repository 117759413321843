.todo {
  $top: 94px;

  .todo__search-box-holder {
    background: $primary;
    height: 220px;
    & > div {
      height: calc(220px - #{$top} + 30px);
      @include media(767px) {
        height: calc(220px - #{$top} - 16px + 30px);
      }

      .todo__search-box {
        width: calc(100% - 60px);
        height: 48px;
        border-radius: 24px;
        overflow: hidden;

        input[type="text"] {
          font-size: 18px;
          outline: none;
          border: none;
        }
      }
    }
  }
  .todo__content {
    margin-top: -$top;
  }
}
