body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  overflow: hidden;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: rgba(255, 0, 221, 0.863);
  font-size: 16px;
  // color: rgba(255, 162, 0, 0.842);
}


// new added

// body{background:red !important;}

.topbar-hold {
  background: rgb(110, 98, 255) !important;
  background: linear-gradient(-226deg, rgba(110, 98, 255, 1) 0%, rgba(64, 136, 250, 1) 48%, rgba(52, 216, 255, 1) 100%) !important;
}

.wrapper-map {
  background: green;
}

html,
body,
#map_canvas {
  margin: 0;
  padding: 0;
  height: 100%;
  position: relative;
}

#map_canvas {
  min-height: 300px;
}

.wrapper {
  background: pink;
  padding: 0.5rem 0.5rem;
  margin: 0 !important;
  max-width: 100%;
}

a.nav-item.active {
  background: linear-gradient(90deg, #6e62ff 0%, #4088fa 48%, #34d8ff 100%) !important;
}

.paper {
  background-color: green;
}

.sidenav .sidenav__hold::after {
  background: linear-gradient(-170deg, #6e62ff 0%, #4088fa 48%, #34d8ff 100%) !important;
}

.themebg {
  background: linear-gradient(-170deg, #6e62ff 0%, #4088fa 48%, #34d8ff 100%) !important;
}

.marginauto {
  margin: 0 auto !important;
}

.MuiButton-containedPrimary {
  color: #ffffff;
  background-color: #6961f5 !important;
}

.term-error {
  margin-bottom: 10px !important;
}

.table-grids {
  .veralign {
    vertical-align: middle;
    display: flex;
    height: 140%;
    align-items: center;
  }
}

.cards {
  background-color: #fff;
  border-radius: 1px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  padding: 10px;
  min-height: 150px;
  width: 100%;
  height: 100%;
  display: inline-block;

  &.d-flexx {
    display: flex;
    justify-content: center;
  }

  .yellow {
    svg {
      color: #fcd462;
    }
  }

  .green {
    svg {
      color: rgb(0, 227, 150);
    }


  }

  svg {
    font-size: 40px;
    margin-right: 15px;
    color: rgb(0, 143, 251);
  }

  h5 {
    font-weight: 500;
  }

  .cards-title {
    margin-bottom: 8px;
  }

}

.d-flex-class {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  z-index: 1;
}

.sidenav .sidenav__hold .nav-item:not(.badge) {
  color: #fff !important;
}

.searchdiv {
  float: right
}

.table-grid {
  background: red;
}

.table-grid .card-title.mb-4 {
  width: 300px;
  float: left;
}

table th,
table td,
table td.px-0 {
  padding: 8px 5px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

table th {
  width: 100%;
  line-height: 15px !important;
}

table td {
  width: 100%;
}

.searchdiv .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
  margin: 0;
}

.sidenav .sidenav__hold .brand-area .brand img {
  height: auto !important;
  margin-right: 0px;
  width: 100%;
  max-width: 30%;
  // max-width: 140px !important;
}

.noboxshadow {
  box-shadow: none !important;
}

.wordwrap {
  word-wrap: break-word !important;
}

.MuiFormLabel-asterisk {
  color: #f44336 !important;
}

.Searchpaper {
  padding: '2px 4px';
  // display: 'flex';
  align-items: 'center';
}

.searchMargin {
  margin: 5px !important;
  width: 84%;
  margin-top: 8px !important;
}

tr.MuiTableRow-root {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.visuallyHidden {
  border: 0;
  clip: 'rect(0 0 0 0)';
  height: 1;
  margin: -1;
  overflow: 'hidden';
  padding: 0;
  position: 'absolute';
  top: 20;
  width: 1;
}

.buttomBorder {
  border-bottom: 1px solid #ccc !important;
}

.bookingHeader {
  font-weight: 700;
}

.marginGrid {
  margin: 15px;
}

.ptagSpace {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.badges {
  background: green !important;
  color: white !important;
}

.badges1 {
  background: #F29339 !important;
  color: white !important;
}


.NotesHeader {
  font-size: 16px;
  font-weight: 600;
  margin-block-start: 0em !important;
  margin-block-end: 0.5em !important;
}

.ptagDetails {
  color: #757575;
  font-weight: 400;
}

.closeButton {
  margin-right: 30px !important;
  margin-bottom: 10px !important;
}

.color-danger {
  color: #f44336 !important;
}

.ptag {
  margin-block-start: 1.5em !important;
}

.half-width {
  width: 50%;
}

// input[type="file"]
// {
//   display: none;
// }

// .fileUpload input.upload
// {
//     display: inline-block;
// }

.profilePic {
  width: 140px;
  margin-top: 5px;
}

.color-white {
  color: white !important;
}

.logo-box {
  text-align: center;

  img {
    width: 183px;
  }
}

.styledKeyboardDatePicker .MuiInputBase-input {
  visibility: hidden;
  width: 0;
}

.chartTable {
  background: #cdd0d8;
  text-align: center;
  margin: 20px;
  margin-top: 10px;
  height: 58px;
}

.chartTableAlign {
  text-align: center;
}

.rowborder {
  border-right: 2px solid #e0e0e0;
}

.revenuedropdown {
  height: 38px;
  margin-top: 5px;

}

.width500 {
  width: 500px !important;
}

.width250 {
  width: 250px !important;
}

.width300 {
  width: 300px !important;
}

.width400 {
  width: 350px !important;
}

.dashboardfilter {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.depositedetails {
  display: flex;
  align-items: center;
  text-align: end;
  padding: 0px 8px
}

.dialougeimageText {
  padding: 5px 5px 5px 5px !important;
}

.locationfilter {
  margin-left: 10px;
  margin-right: 10px;
}

.rowborder1 {
  border-top: 1px solid #e0e0e0
}

.card {
  border-bottom: 1px solid #fff;
}

.Tableborder {
  border: 1px solid #e0e0e0;
}

@media screen and (max-width: 768px) {

  .dashboardfilter {
    display: flex;
    align-items: center;
    margin-top: 10px;
    flex-direction: column;
  }

  .locationfilter {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.marginTenpx {
  margin-top: 10px !important;
}

.avoid-clicks {
  pointer-events: none;
}

.img-w-100 img {
  width: 100%;
}

.button-right {
  justify-content: flex-end;
}


@media screen and (max-width: 1367px) {

  table td button {
    padding: 8px !important;
    line-height: normal !important;
  }
}


.Searchpaper button {
  float: right;
}

.bluebtn {
  background: #4d58f5 !important;
  color: #fff !important;
}

.table-login-btn {
  background: transparent !important;
  color: #4d58f5 !important;
  border: none !important;
  font-weight: bold !important;
  font-size: 18px !important;
}

.resetpw-img img {
  min-width: 280px;
}

.login-title {
  font-size: 18px;
  margin-bottom: 24px;
}

.MuiSnackbarContent-message {
  color: white !important;
}

.topbar-title {
  font-weight: bold !important;
  font-size: 18px !important;
}

.marginTop15px {
  margin-top: 15px !important;
}

.AddVehicle {
  color: #606efe;
  cursor: pointer;
  margin: 5px !important;
}

.width200 {
  width: 200px;
  max-width: 200px;
}

.ArrowDownwardIconDown {
  font-size: 15px !important;
  color: red;
}

.ArrowDownwardIconUP {
  font-size: 15px !important;
}

.textColor {
  color: #5a72fd !important;
}

.minusrevenue {
  color: red;
}

.openpicker {
  margin-left: 10px !important;
  color: #606efe !important;
  cursor: pointer !important;
}

.dashboarddatepicker {
  display: none !important;
}

.dashboardTable {
  margin-top: 30px !important
}

.modalradius {
  border-radius: 10px !important;
}

.h6-style {
  font-size: 13px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  color: #000 !important;
  font-weight: 600 !important;
}

.font-14-style {
  font-size: 13px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  color: rgba(0, 0, 0, 0.72) !important;
  font-weight: 500 !important;
}

.page-heading {
  color: #000000 !important;
  font-size: 16px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  text-transform: uppercase;
  font-weight: 500 !important;
}

.payment {
  color: green !important;
}

.heading-card {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: uppercase;
  text-align: center;
}

.textField1 {
  width: 100%;
  margin: 0px 0px 10px;
  font-size: 100px !important;
  font-family: "SF Pro Text" !important;
}

#time-picker-label {
  color: grey !important;
  font-size: 16px !important;
}

#DatePicker-label-label {
  color: grey !important;
  font-size: 16px !important;
}

.marginGrid1 {
  margin: 1px !important;
}

.marginGrid2 {
  margin-top: 10px !important;
}

.NotesHeader1 {
  font-size: 15px !important;
  font-weight: 600 !important;
  margin-left: 10px !important;
}

.BookingRevenue {
  display: flex;
  align-items: center;
  text-align: start;
  padding: 4px 8px !important
}

.NotesHeader3 {
  font-size: 15px !important;
  font-weight: 600 !important;
  width: 55% !important
}

#MuiOutlinedInput-input {
  padding: 0px 10px !important;
  height: 25px !important;
  width: 90px !important;
}

.addBusinessOffice {
  font-size: 1.25rem !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 500 !important;
  line-height: 1.6 !important;
  letter-spacing: 0.0075em !important;
  margin-right: 1.25rem !important;
  margin-left: 7px !important;
}

.errorTextColor {
  color: #ff0000 !important;
}

.w-600 {
  width: 570px !important;
}