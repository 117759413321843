.cart {
  min-width: 900px;
  overflow-x: scroll;
}

.ecommerce__product-card {
  position: relative;

  .product__image-box {
    .product__price {
      position: absolute;
      font-weight: 500;
      background: $primary;
      color: white;
      padding: 4px 12px;
      right: 0;
      top: 24px;
      border-top-left-radius: 26px;
      border-bottom-left-radius: 26px;
      overflow: hidden;
      z-index: 4;
    }
    .image-box__overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: none;
      background: rgba(0, 0, 0, 0.74);
      z-index: 2;
    }
  }

  &:hover {
    .image-box__overlay {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.checkout {
  .checkout__product-list {
    hr:last-of-type {
      display: none !important;
    }
  }
}
