.signup {
  // background: #1A2038;
  background: linear-gradient(-170deg, #6e62ff 0%, #4088fa 48%, #34d8ff 100%) !important;
  

  .signup-card {
    max-width: 800px;
    border-radius: 12px !important;
    img {
      // width: 200px;
    }
  }
}
