.pricing {
  .pricing__card {
    border-radius: 20px;
    overflow: hidden;
    h1,
    h5 {
      margin: 0;
      color: $primary !important;
      text-transform: uppercase;
    }

    h5 {
      font-weight: 400;
      letter-spacing: 3px;
    }

    h1 {
      line-height: 1;
      font-size: 3rem;
      padding-top: 8px;
      padding-bottom: 4px;
      font-weight: 500;
    }

    p {
      color: $text-muted;
      font-size: 1rem;
    }

    img {
      height: 150px;
      width: 150px;
    }
  }
}
