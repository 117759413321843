.swiper-slide {
  height: auto;
}

.swiper-pagination-bullet {
  opacity: 1;
  background: rgba(0, 0, 0, 0.55);
  transition: transform 400ms cubic-bezier(0.17, 0.67, 0.83, 0.67);
}

.bullet-active {
  transform: scale(1.8);
}

.carousel__button-next,
.carousel__button-prev {
  position: absolute !important;
  top: 50%;
  transform: translateY(calc(-50% - 50px));
  z-index: 1;
}
.carousel__button-prev {
  left: 0px;
}
.carousel__button-next {
  right: 0px;
}
